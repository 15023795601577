import * as React from "react"

const TrofeoIcon = (props) => (
  <svg
    width={52}
    height={52}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#fff">
      <path d="M45.906 3.047a6.402 6.402 0 0 0-3.148.847v-2.37C42.758.681 42.076 0 41.234 0H10.766c-.842 0-1.524.682-1.524 1.523v2.371a6.402 6.402 0 0 0-3.148-.847A6.1 6.1 0 0 0 0 9.14v5.171a6.08 6.08 0 0 0 2.714 5.07L9.92 24.12c.026.018.058.01.085.026 1.125 3.119 3.489 5.636 6.977 7.265 2.264 1.059 3.756 3.173 4.23 5.356h9.496c.32-2.112 1.527-4.206 3.404-5.268 3.657-2.07 6.42-4.245 7.753-7.314.07-.033.148-.021.214-.065l7.206-4.737A6.08 6.08 0 0 0 52 14.312V9.141a6.1 6.1 0 0 0-6.094-6.094Zm-41.502 13.8a3.042 3.042 0 0 1-1.357-2.535V9.141a3.05 3.05 0 0 1 3.047-3.047c1.68 0 3.148 1.367 3.148 3.047v10.664c0 .072.013.138.015.21l-4.853-3.168Zm30.25-2.624-3.222 3.14.76 4.434a1.522 1.522 0 0 1-2.21 1.605L26 21.31l-3.983 2.092a1.522 1.522 0 0 1-2.21-1.605l.761-4.434-3.222-3.14a1.524 1.524 0 0 1 .845-2.598l4.451-.647 1.992-4.035c.512-1.041 2.22-1.041 2.732 0l1.992 4.035 4.45.647a1.524 1.524 0 0 1 .846 2.598Zm14.3.089c0 1.02-.508 1.968-1.358 2.535l-4.855 3.17c.001-.075.017-.138.017-.212V9.14c0-1.68 1.469-3.047 3.148-3.047a3.05 3.05 0 0 1 3.047 3.047v5.171ZM38.188 47.43h-6.094a1.523 1.523 0 1 1 0-3.047h4.57v-1.524a3.047 3.047 0 0 0-3.047-3.047H18.383a3.047 3.047 0 0 0-3.047 3.047v1.524h4.57a1.523 1.523 0 1 1 0 3.047h-6.093c-.842 0-1.524.682-1.524 1.523v1.524c0 .84.682 1.523 1.524 1.523h24.374c.842 0 1.524-.682 1.524-1.523v-1.524c0-.841-.682-1.523-1.523-1.523Z" />
      <path d="m30.316 14.196-2.188-.319a1.52 1.52 0 0 1-1.148-.833L26 11.06l-.98 1.984a1.52 1.52 0 0 1-1.147.833l-2.189.319 1.585 1.544c.358.35.522.854.437 1.348l-.373 2.182 1.959-1.03a1.514 1.514 0 0 1 1.416 0l1.96 1.03-.374-2.182a1.523 1.523 0 0 1 .438-1.348l1.584-1.544Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h52v52H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default TrofeoIcon
