import * as React from "react"

const VocabularioIcon = (props) =>  (
  <svg
    width={58}
    height={61}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55.414 19.046H30.699V39.07a2.594 2.594 0 0 1-2.586 2.602h-.825v16.444a2.594 2.594 0 0 0 2.586 2.602h25.54A2.594 2.594 0 0 0 58 58.117V21.648a2.594 2.594 0 0 0-2.586-2.602Zm-1.182 35.279a2.594 2.594 0 0 1-2.586 2.602H33.655a2.594 2.594 0 0 1-2.586-2.602V25.44a2.594 2.594 0 0 1 2.586-2.602h17.99a2.594 2.594 0 0 1 2.587 2.602v28.885Z"
      fill="#fff"
    />
    <path
      d="M35.17 49.876V29.89c0-1.103.898-2.008 1.994-2.008h1.761c2.18 0 3.768.136 4.778.421 1.429.384 2.574 1.09 3.423 2.12a5.62 5.62 0 0 1 1.269 3.655 5.43 5.43 0 0 1-.567 2.49 5.688 5.688 0 0 1-1.219 1.55.59.59 0 0 0-.037.83c.037.05.087.086.148.111 1.072.608 1.872 1.339 2.414 2.169.677 1.028 1.01 2.243 1.01 3.643a6.764 6.764 0 0 1-1.047 3.693 6.6 6.6 0 0 1-2.685 2.49c-1.096.546-2.61.818-4.544.818h-4.716c-1.096.013-1.983-.88-1.983-1.995Zm4.543-16.691v3.16c0 .52.42.954.949.954h.049c1.108 0 1.933-.236 2.475-.707.542-.47.813-1.102.813-1.908 0-.743-.259-1.35-.764-1.784-.505-.434-1.293-.657-2.327-.657h-.234c-.53 0-.96.421-.96.942Zm0 9.132v4.25c0 .521.42.955.949.955h.197c1.884 0 3.164-.236 3.817-.719s.985-1.177.985-2.094c0-1.029-.382-1.847-1.157-2.454-.776-.607-2.057-.892-3.842-.892a.96.96 0 0 0-.949.954ZM28.113 0H2.586A2.594 2.594 0 0 0 0 2.602v36.469a2.594 2.594 0 0 0 2.586 2.602h25.527A2.594 2.594 0 0 0 30.7 39.07V2.602A2.594 2.594 0 0 0 28.113 0Zm-1.182 35.279a2.594 2.594 0 0 1-2.586 2.602H6.355a2.594 2.594 0 0 1-2.587-2.602V6.394a2.594 2.594 0 0 1 2.586-2.602h17.991a2.594 2.594 0 0 1 2.586 2.602V35.28Z"
      fill="#fff"
    />
    <path
      d="M15.356 8.835a3.36 3.36 0 0 1 3.14 2.169l7.216 18.823a2.225 2.225 0 0 1-1.256 2.862c-.258.1-.517.149-.8.149a2.185 2.185 0 0 1-2.057-1.425l-.887-2.33a1.822 1.822 0 0 0-1.699-1.19h-7.265c-.751 0-1.429.459-1.7 1.165l-.935 2.38a2.206 2.206 0 0 1-2.045 1.4h-.024a2.206 2.206 0 0 1-2.057-3l7.241-18.834a3.356 3.356 0 0 1 3.128-2.169Zm-.653 8.117-2.13 5.49a.733.733 0 0 0 .69 1.003h4.26a.722.722 0 0 0 .727-.731.612.612 0 0 0-.05-.26l-2.118-5.49a.726.726 0 0 0-.948-.421.741.741 0 0 0-.43.409Z"
      fill="#fff"
    />
  </svg>
)

export default VocabularioIcon
