export const responseformualrio={

    Creado:{
        Creado:"Ítem creado con éxito",
        NoCreado:"No se pudo crear el ítem",
        Repetido:"Item ya repetido",
    },
    Editadar:{
        editadoExito: "Ítem editado con éxito",
        editadoFracaso:"No se pudo editar",
    },
    Borrar:{
        BorrarItem:"Ítem borrado",
        NoBorrarItem:"No se pudo Borrar",
    },Desactivar:{
        Desactivar:"Ítem desactivado",
        NoDesactivar:"No se pudo desactivar",
    },Activar:{
        Activar:"Ítem activado",
        NoActivar:"No se pudo activar",
    },Mostrar:{
        NoMostrar:"No se puede mostrar"
    }
}

